import { Parallax } from 'react-parallax';
import Desert2 from '../assets/desert2.jpg';
import Expertise from '../assets/expertise.png'

const ImageTwo = () => (
    <Parallax  className='image2' bgImage={Desert2}  strength={600}>
        <div className='content2'>
        <span   className='img-txt2'>My Expertise</span>
        </div>
        <div    id = 'Image-TwoLink' className='picture-expertise'   > <img src={Expertise}></img></div>
    </Parallax>
);
export default ImageTwo;