import { Parallax } from 'react-parallax';
import Desert3 from '../assets/desert3.jpg';
import AboutMe from '../assets/AboutMe.png';
import LinkedIn from '../assets/Linkedin.png';
import resume from '../assets/resume.png';
import Rdownload from '../assets/Kip_Taylor_Resume.pdf';

const ImageThree = () => (
    <Parallax className='image3' bgImage={Desert3}  strength={600}>
    
        <div className='content3'>
        <span  className='img-txt3'> About Me</span>
        </div>
        <div  className='picture-aboutme'  > <img src={AboutMe}></img></div>
        <div className='aboutmediv'> 
        <span className='lanchor'><a  href="https://www.linkedin.com/in/kip-taylor/"> <img  src={LinkedIn} ></img> </a>  <a href={Rdownload} > <img src={resume}></img> </a>  </span>
        </div>
        <p id= 'Image-ThreeLink'></p>
    </Parallax>
);
export default ImageThree;