import ImageOne from "./components/ImageOne";
import ImageTwo from "./components/ImageTwo";
import ImageThree from "./components/ImageThree";
import React from "react";




function App() {
  return (
    
    <div>
      <ImageOne />
      <ImageTwo id='Image-TwoLink' />
      <ImageThree />
    </div>
  );
}

export default App;
