import { Parallax } from 'react-parallax';
import Desert from '../assets/desert.jpg';
import { BiDownArrowAlt } from "react-icons/bi";
import React from 'react';
import LinkedIn from '../assets/Linkedin.png';



const ImageOne = () => (
    
    <Parallax className='image1' bgImage={Desert} strength={900}>
  
        <div className='content1'>
        <span className='img-txt1'> Kip  taylor </span>
        <span className='img-txt1-1'> <br></br><br></br>
        Software Engineer, Games Developer, and Designer
        </span>
        </div>
    </Parallax>
    
);

export default ImageOne;
